export type CountryType =
  | "US"
  | "MX"
  | "AR"
  | "EC"
  | "SV"
  | "PA"
  | "PR"
  | "BO"
  | "CL"
  | "CO"
  | "CR"
  | "DO"
  | "GT"
  | "HN"
  | "NI"
  | "PY"
  | "PE"
  | "UY"
  | "VE";

export type LocaleType = "es" | "pt";

// this is a list of allowed locales
export const Locales: LocaleType[] = ["es", "pt"];
export const FallbackLocale: LocaleType = "es";

/** @deprecated */
export type Language = "en-US" | "es-419";
export type TranslateFn = (key: string) => string;

export interface TranslationsModel {
  [key: string]: string;
}

export const LOCALE_KEY = "locale";

import { generateDeviceId } from "lib/env";
import { Fragment, useState } from "react";
import SharedHead from "components/head";
import Router from "routes/router";
import CookieYes from "components/external/cookie-yes";
import GTagManager from "components/external/google-tag-manager";
import PaddleJS from "components/external/paddle";
import ErrorBoundary from "components/error-boundary";
import LocaleProvider from "providers/locale";
import ThemeProvider from "providers/theme";
import OneTrust from "components/external/onetrust";
import { read, write } from "lib/storage";

function getRandomIntInclusive(min: number, max: number) {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled);
}

const KEY = "COOKIE_CONSENT_TYPE";

const App = () => {
  // for A/B testing
  const [whichCookieContent] = useState<string>(
    read<string>(KEY) || ["onetrust", "cookieyes"][getRandomIntInclusive(0, 1)]
  );

  // make sure clientUuid exists
  generateDeviceId();
  write<string>(KEY, whichCookieContent);
  console.info(`whichCookieContent = ${whichCookieContent}`);
  return (
    <Fragment>
      {/* these will be in <head /> */}
      {import.meta.env.VITE_PUBLIC_NAME !== "production" && (
        <meta name="robots" content="noindex,follow" />
      )}

      {/* shared head defaults, goes to <head /> */}
      <SharedHead />

      {/* load cookieyes, goes to <head /> */}
      {whichCookieContent === "cookieyes" && <CookieYes />}
      {whichCookieContent === "onetrust" && <OneTrust />}

      {/* load paddle, goes to <head /> */}
      <PaddleJS />

      {/* load gtm, goes to <head /> */}
      <GTagManager />

      {/* main thing, goes to <body /> */}
      <ErrorBoundary>
        <LocaleProvider>
          <ThemeProvider>
            <Router />
          </ThemeProvider>
        </LocaleProvider>
      </ErrorBoundary>
    </Fragment>
  );
};

export default App;

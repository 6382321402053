import HelmetWrapper from "components/helmet";
import { getBrowserLocale } from "lib/locale";
import { read, write } from "lib/storage";
import { FallbackLocale, LOCALE_KEY, Locales, LocaleType } from "models/locale";
import { createContext, FunctionComponent, useEffect, useState } from "react";

interface LocaleContextInterface {
  locale: LocaleType;
  updateLocale: (newLocale: LocaleType) => void;
}

export const LocaleContext = createContext<LocaleContextInterface | undefined>(
  undefined
);

interface LocaleProviderProps {
  children: any;
}

const getPersistedLocale = () => read<LocaleType | undefined>(LOCALE_KEY);

const LocaleProvider: FunctionComponent<LocaleProviderProps> = ({
  children,
}) => {
  let parsed = getPersistedLocale() || getBrowserLocale();
  if (!Locales.includes(parsed)) {
    console.info(`Locale ${parsed} not supported, use ${FallbackLocale}`);
    parsed = FallbackLocale;
  }
  const [locale, toggleLocale] = useState<LocaleType>(parsed);

  const updateLocale = (newLocale: LocaleType) => {
    if (newLocale !== locale) {
      console.info(`Update locale to ${newLocale}`);
    }

    // update internal locale (changes html lang)
    toggleLocale(newLocale);

    // persist
    write(LOCALE_KEY, newLocale);
  };

  useEffect(() => {
    // persist initial values
    updateLocale(locale);
  }, []);

  console.info(`App locale = ${locale}`);
  return (
    <LocaleContext.Provider value={{ locale, updateLocale }}>
      <HelmetWrapper>
        <html lang={locale} />
      </HelmetWrapper>
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;

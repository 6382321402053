import HelmetWrapper from "components/helmet";
import { getCookieYesId, isCookieYesEnabled } from "lib/cookie-yes";
import { gtmCookieYes } from "lib/gtm";
import { FunctionComponent, useEffect } from "react";

const CookieYes: FunctionComponent = () => {
  if (isCookieYesEnabled() === false) {
    console.info("Cookie consent not available in this environment.");
    return null;
  }

  useEffect(() => {
    gtmCookieYes();
  }, []);

  const url = `https://cdn-cookieyes.com/client_data/${getCookieYesId()}/script.js`;
  return (
    <HelmetWrapper>
      <script src={url}></script>
    </HelmetWrapper>
  );
};

export default CookieYes;

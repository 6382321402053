import { CountryType } from "./locale";
import { QuestionnaireVersion } from "./questionnaire";

export const CONFIG_STATE_KEY = "config";

export const DEVICE_ID_KEY = "deviceId";

export interface ConfigModel {
  country: CountryType;
  uiLanguage: string;
  uuid: string;

  // feature flags
  questionnaireVersion?: QuestionnaireVersion;
  completed?: boolean;
  entitlementExists?: boolean;
}
